<template>
  <div id="topBrandsWrapper" class="top-brand-wrapper" :data-test-id="elementTest">
    <CarouselContainer
      :title="title"
      :subtitle="$t('home.topBrandsMessage')"
      :link-label="$t('home.topBrandsButton')"
      :link-src="'brands'"
      :data-test-id="elementTest + '-carousel'"
      :test-id="elementTest"
      element-parent="topBrandsWrapper"
      item-width="150"
      item-height="65"
    >
      <template #contentCarouselContainer>
        <NuxtLinkLocale
          v-for="(brand, i) in brands"
          :key="brand.uid"
          :to="{ name: 'brand-brand', params: { brand: brand.slug } }"
          :data-test-id="`${elementTest}-carousel-item-${i}`"
          class="featured-brands-carousel-item"
        >
          <ImageLazy
            v-if="imageForBrand(brand)"
            :src="imageForBrand(brand)"
            :alt="brand.name"
            :title="brand.name"
            width="150"
            max-height="65"
          />
          <h3 v-if="!imageForBrand(brand)" class="brand-logo-placeholder">
            <div>{{ brand.name }}</div>
          </h3>
        </NuxtLinkLocale>
      </template>
    </CarouselContainer>
  </div>
</template>

<script setup>
import CarouselContainer from '@/components/Carousel/CarouselContainer'

defineProps({
  title: { type: String, default: null },
  brands: { type: Array, default: () => [] },
  elementTest: { type: String, default: 'featured-brands' },
})

const imageForBrand = brand => {
  if (brand?.logo_url) {
    return brand?.logo_url
  } else if (brand?.image_url) {
    return brand?.image_url
  } else if (brand?.image) {
    return brand?.image.filename
  }
  return ''
}
</script>

<style lang="scss">
.top-brand-wrapper {
  @include mq($mq-sm) {
    padding: 0 $spacing-md;
  }
}
.featured-brands-carousel-item {
  display: flex;
  flex: 0 0 150px;
  height: 65px;
  text-decoration: none;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    @include mqmin($mq-sm) {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
  &:hover {
    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}
.brand-logo-placeholder {
  width: 150px;
  height: 65px;
  background-color: none;
  border-radius: $spacing-sm;
  position: relative;
  &:hover {
    background-color: var(--bg-grey);
  }
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: pxToRem(15px);
    font-weight: 350;
    color: var(--dark-blue);
  }
}
</style>
